.portfolio {
  display: flex;

  &__left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    margin-right: 15px;
    margin-bottom: 0;
  }

  &__portfolio-value {
    font-size: 40px;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__change {
    display: flex;
    align-items: center;
  }

  &__change-value {
    flex: 1;
    color: red;
  }

  &__change-select {
    flex: 1;
  }

  &__cards {
    margin-top: 10px;
  }

  &__chart {
    margin-left: 20px;
    flex: 1;
    // width: calc(100% - 200px);
    // max-width: calc(100% - 200px);
  }

  &__cards-line {
    &:not(:first-child) {
      margin-top: 10px;
    }

    display: flex;
    // justify-content: space-between;
  }

  &__card {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
