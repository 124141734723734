.remove-liquidity-modal {
  padding: 15px;
  display: block;

  &__input {
    margin-top: 15px;
  }

  &__remove-liquidity-button {
    width: 100%;
    margin-top: 20px;
  }

  &__title {
    display: flex;
  }

  &__market-instrument-pair {
    margin-left: 5px;
  }
}
