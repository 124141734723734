@import 'styles/variables/colors';

.toggle {
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  align-items: center;

  &__root {
    padding: 0 !important;
    width: 65px !important;
    height: 36px !important;
  }

  & &__base {
    padding: 3px !important;
  }

  &_checked {
    transform: translateX(29px) !important;
  }

  & &__thumb {
    width: 30px;
    height: 30px;
    background-color: $color-light-bg;
  }

  &_checked &__thumb {
    background-color: $color-fg;
  }

  & &__track {
    border-radius: 100px;
    opacity: 1 !important;
    background-color: $color-bg-3;
  }

  &_checked + &__track {
    background-color: $color-primary !important;
  }
}
