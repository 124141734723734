@import 'styles/variables/colors';

.farm-page {
  &__header {
    padding: 15px;
    border-bottom: 1px solid $color-border;
  }

  &__title {
    text-align: center;
  }

  &__action-panel {
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
  }

  &__main {
    display: grid;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 40px 100px;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, 350px);
  }
}
