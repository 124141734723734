@import 'styles/mixins/common';

.tumbler {
  --thumb-height: calc(var(--tumbler-height) - 2 * var(--tumbler-offset));
  --thumb-border-radius: calc(var(--tumbler-border-radius) - var(--tumbler-offset));
  --tumbler-delta: calc(var(--tumbler-width) - 100% - 2 * var(--tumbler-offset));

  &_size_s {
    --tumbler-height: 20px;
    --tumbler-width: 40px;
    --tumbler-border-radius: 10px;
    --tumbler-offset: 1px;
    --thumb-width: 18px;
  }

  &_size_m {
    --tumbler-height: 25px;
    --tumbler-width: 40px;
    --tumbler-border-radius: 8px;
    --tumbler-offset: 2px;
    --thumb-width: 18px;
  }

  &_size_l {
    --tumbler-height: 30px;
    --tumbler-width: 40px;
    --tumbler-border-radius: 8px;
    --tumbler-offset: 2px;
    --thumb-width: 18px;
  }

  display: inline-flex;
  align-items: center;

  &__label {
    cursor: pointer;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  &__button {
    width: var(--tumbler-width);
    height: var(--tumbler-height);
    border-radius: var(--tumbler-border-radius);
    background-color: $color-light-bg;
    position: relative;
    border: none;
    cursor: pointer;

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: var(--thumb-width);
      height: var(--thumb-height);
      border-radius: var(--thumb-border-radius);
      background-color: $color-bg;
      top: var(--tumbler-offset);
      left: var(--tumbler-offset);
      transition: 200ms;
    }

    &_checked {
      &:after {
        transform: translateX(var(--tumbler-delta));
      }
    }

    &_color_primary:after {
      background-color: $color-primary;
    }

    &_color_positive:after {
      background-color: $color-positive;
    }

    &_color_negative:after {
      background-color: $color-danger;
    }
  }
}
