.insurance-cards {
  display: grid;
  justify-items: center;
  margin-top: 10px;
  grid-auto-columns: 1fr;
  grid-gap: 10px;

  &__card-value {
    display: grid;
    //margin-top: 5px;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    align-items: center;

    &-tooltip {
      transform: translateY(-2px);
    }
  }
}
