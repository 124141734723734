.receive-currency-page {
  display: grid;
  grid-template-columns: 400px;
  grid-row-gap: 20px;
  justify-content: center;
}

@media only screen and (max-width: 980px) {
  .receive-currency-page {
    grid-template-columns: 100%;
  }
}
