@import 'styles/variables/common';
@import 'styles/variables/colors';

$social-colors: (
  'twitter': #3cf,
  'telegram': #0088cc,
  'discord': #7289da,
  'medium': #000000,
  'docs': #f77d05,
);

.sidebar {
  display: grid;
  grid-template-rows: 1fr 85px;
  width: $sidebar-width;
  min-width: $sidebar-width;
  border-right: 1px solid $color-border;

  &__nav {
    list-style-type: none;
    padding: 0;
    margin: 40px 0 0 0;
  }

  &__nav-item {
    display: block;
  }

  &__nav-link {
    $border-left-width: 4px;
    $padding: 15px;

    display: block;
    color: $color-fg;
    text-decoration: none;
    padding: $padding;
    padding-left: $padding - $border-left-width;
    border-left: $border-left-width solid transparent;
    transition: 0.2s border-color, 0.2s background-color;
    margin-bottom: 5px;

    &:hover {
      border-color: darken($color-accent, 30%);
    }

    &_active {
      // background-color: lighten($color-bg, 5%);
      border-color: $color-accent;
    }

    &-icon {
      width: 20px;
      margin-right: 1em;
    }
  }

  &__social-media {
    border-top: 1px solid #383536;
  }

  &__social-title {
    padding: 10px 15px;
  }

  &__social-links {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    padding: 0 15px;
    justify-content: space-between;
  }

  &__social-link {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    @each $name, $color in $social-colors {
      &_color_#{$name} {
        background-color: $color;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .sidebar {
    width: $sidebar-width-mobile;
    min-width: $sidebar-width-mobile;

    &__nav-link span {
      display: none;
    }

    &__social-title {
      display: none;
    }

    &__social-links {
      grid-auto-flow: row;
    }
  }
}
