@import 'styles/mixins/common';

.ratio-buttons {
  display: flex;
  justify-content: flex-end;

  &__button {
    & + & {
      margin-left: 10px;
    }
  }
}
