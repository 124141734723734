.wallet-menu {
  $menu: &__menu;

  & > &__button {
    transition: 0.2s background-color, 0.2s color;
    color: #999;
    background-color: #262338;

    &:hover {
      background-color: #494949;
      color: #fff;
    }
  }

  &__menu {
    font-size: 14px;
    background-color: #262338 !important;
  }

  &__menu &__menu-item {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    transition: 0.2s background-color, 0.2s color;
    color: #999;

    &:hover {
      background-color: #494949;
      color: #fff;
    }
  }
}
