.numeric-value {
  display: flex;
  align-items: center;

  &__negative-diff-icon {
    & path {
      fill: red;
    }
  }

  &__positive-diff-icon {
    & path {
      fill: green;
    }
  }

  &__diff {
    margin-right: 5px;
  }

  &__text {
    font-size: 16px;

    &_diff {
      &_positive {
        color: #0bb30b;
      }

      &_negative {
        color: #ff0000;
      }
    }
  }
}
