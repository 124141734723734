@import 'styles/variables/colors';

.vesting-claim-card {
  width: 100%;
  max-width: 500px;
  min-height: 600px;

  display: flex;
  flex-flow: column;

  background: $color-bg-1;
  border: 1px solid $color-fg-inverted;
  border-radius: 80px;

  &__preview, &__body {
    display: flex;
    flex-flow: column;
    height: 50%;
  }

  &__preview {
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 50px 0;

    border-bottom: 1px solid $color-fg-inverted;
  }

  &__body {
    padding: 30px 40px 0 40px;
  }

  &__column {
    display: flex;
    flex-flow: column;
    text-align: center;

    > :first-child {
      margin-bottom: 10px;
    }

    &_available-strp {
      margin-bottom: 30px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_pending {
      justify-content: center;
      margin-bottom: 60px;

      & > :first-child {
        margin-right: 30px;
      }
      & > div {
        flex-grow: 1;
      }
    }
  }

  &__column, &__row {
    &_accent-background {
      padding: 10px;
      border-radius: 5px;
      background: $color-bg-2;
    }
  }

  &__claim-button {
    width: 100px;
    height: 50px !important;
    font-size: 16px !important;
  }

  &__label {
    font-size: 18px;
  }
  &__value {
    font-size: 16px;
  }
}
