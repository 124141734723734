@import 'styles/variables/colors';

.currency-input {
  $root: &;

  position: relative;

  &__content {
    display: flex;
    position: relative;
    padding: 5px;
    border: 1px solid $color-bg-2;
    border-radius: 5px;
    transition: border-color 100ms;

    #{$root}_invalid & {
      border-color: $color-error;
    }
  }

  &__input-and-label {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__input {
    padding-right: 25px;
    border: none;
    width: 100%;
    height: 25px;
    // border-radius: 5px;
    background: transparent;
    color: $color-fg;
    outline: none;

    &:read-only {
      cursor: auto;
    }
  }

  &__input-container {
    width: 100%;
    position: relative;
    display: flex;
    border: solid 1px;
    border-radius: 5px;
  }

  &__clear-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__right-part-container {
    margin-left: 10px;
    min-height: 48px;
  }

  &__error {
    box-sizing: border-box;
    padding-bottom: 3px;
    min-height: calc(1.6em + 3px);
    color: $color-error;
    opacity: 0;
    animation: error 100ms forwards;

    &_hidden {
      visibility: hidden;
    }
  }

  @keyframes error {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
