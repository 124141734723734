@import 'styles/mixins/common';

.market-board {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #282425;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 15px;
  }

  &__ratio {
    display: grid;
    padding: 10px 15px;
  }

  &__form {
    padding: 10px 15px;
  }

  &__footer {
    display: grid;
    row-gap: 10px;
    padding: 10px 15px;
    background-color: rgba(75, 88, 78, 0.22);

    .MuiCircularProgress-colorPrimary {
      margin: 0 auto;
    }
  }
}
