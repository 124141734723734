@import 'styles/mixins/common';

.stake-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;

  &__icon {
    font-size: 24px;
    padding-bottom: 2px;
  }

  &__button {
    @include reset-button;
    display: grid;
    align-items: center;
    column-gap: 10px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0px 10px;

    &_type {
      &_long {
        grid-template-columns: max-content 1fr;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: $color_positive;
        text-align: left;

        &:hover {
          background-color: lighten($color_positive, 5%);
        }
      }
      &_short {
        grid-template-columns: 1fr max-content;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: $color_danger;
        text-align: right;

        &:hover {
          background-color: lighten($color_danger, 5%);
        }
      }
    }

    &_disabled {
      background-color: $color-bg-3;
      color: darken($color-fg, 60%);
      cursor: auto;

      &:hover {
        background-color: $color-bg-3;
      }
    }
  }
}
