@import 'styles/variables/colors';
@import 'styles/mixins/responsive';
@import 'styles/variables/spacing';
@import 'styles/variables/common';

.presenter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  transition: 0.2s opacity ease-out;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;

  &.presenting {
    opacity: 1;
    pointer-events: all;
    touch-action: auto;
  }

  .modal {
    position: absolute;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-out, opacity 0.2s;
    opacity: 0;
    transform: translate3d(100px, 0, 0);
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.focused {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.stashed {
      transform: translate3d(-100px, 0, 0);
      opacity: 0;
    }
  }
}
