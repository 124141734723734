
.pendingBox {
  min-width: 15px;
}

.pending {
  padding: 0px 15px;
  font-size: 15px;
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pendingText {
  margin-left: 10px;
}
