@import 'styles/variables/colors';

.value-card {
  display: flex;
  width: 150px;
  padding: 5px;
  border-radius: 5px;
  flex-direction: column;
  background: $color-bg-2;
  align-items: center;
}
