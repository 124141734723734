@import 'styles/variables/colors';

.select {
  $r: &;
  cursor: pointer;

  &__active-option-container {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background: $color-bg-2;
    transition: background 200ms;
    min-height: 40px;

    &:hover {
      background: $color-bg-3;
    }

    #{$r}_expanded & {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      background: $color-bg-3;
    }
  }

  &__active-option-icon {
    margin-left: 10px;
    margin-right: 6px;
  }

  &__options {
    position: relative;
    z-index: 101;
    max-height: 300px;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    background: $color-bg-3;
    position: absolute;

    &_displayed {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      z-index: 999;
    }
  }

  &__option {
    $option: &;
    position: relative;
    padding: 5px 0;
    cursor: pointer;

    &:not(&_active):hover &-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: darken($color-accent, 30%);
      width: calc(100% + 10px);
      transform: translateX(-5px);
    }

    &_active &-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% + 10px);
      transform: translateX(-5px);
      background: $color-accent;
    }

    #{$option}_active &-text {
      display: flex;
      color: $color-fg-inverted;
    }

    &-text {
      position: relative;
      z-index: 2;
    }
  }
}
