.staked {
  &__controls {
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2px;
    align-items: center;
  }

  &__control {
    cursor: pointer;
    user-select: none;
  }
}
