.total-deposits {
  display: grid;
  row-gap: 3px;
  justify-content: center;
  justify-items: center;

  &__label {
    width: 100%;
    padding: 3px 10px;
    text-align: center;
    background-color: #f0ffff17;
  }
}
