.leverage {
  display: flex;
  width: 100%;

  &__range-input {
    flex: 1;
    padding-left: 6px;
  }

  &__text {
    width: 20px;
    margin-left: 15px;
  }
}
