@import 'styles/variables/colors.scss';

.market-widget {
  $r: &;
  border-radius: 20px;
  padding: 15px;
  background-color: $color-bg-0;

  &__header {
    font-size: 16px;
    display: grid;
    align-items: flex-start;
    grid-auto-flow: column;
    margin-bottom: 15px;
  }

  &__logo {
    width: 50px;
  }

  &__content {
    display: grid;
    margin-bottom: 15px;
    row-gap: 10px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    color: $color-light-fg;
    &_bold {
      font-weight: bold;
    }
  }

  &__value {
    &_bold {
      font-weight: bold;
    }
  }

  &__harvest-button-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__details {
    border-top: 1px solid $color-border;
  }

  &__expand-icon {
    color: white;
  }

  &__market-link {
    display: grid;
    grid-auto-flow: column;
    column-gap: 5px;
    align-items: center;
    color: white;
  }

  #{$r} &__market-link-icon {
    color: $color-accent;
    font-size: 14px;
  }

  #{$r} &__expansion-panel {
    box-shadow: none;
    background-color: transparent;
  }

  #{$r} &__expansion-summary-content {
    flex-grow: 0;
  }

  #{$r} &__expansion-summary {
    color: white;
  }

  #{$r} &__expansion-content {
    display: grid;
    row-gap: 15px;
    color: white;
    padding: 0;
  }
}
