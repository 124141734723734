@import 'styles/mixins/common';

$padding-h: 10px;

.data-grid {
  width: 100%;

  &__head {
    &_hidden {
      display: none;
    }
  }

  &__heading {
    text-align: left;
    background-color: rgba(255, 255, 255, .1);
    height: 20px;
    padding: 5px $padding-h;
    font-weight: 400;
  }

  &__row {
    transition: .3s background-color;

    &:hover {
      background-color: rgba(255, 255, 255, .05);
    }
  }

  &__cell {
  }

  &__cell-inner {
    padding: 7px $padding-h;
    display: flex;

    &_align_start {
      justify-content: flex-start;
    }

    &_align_center {
      justify-content: center;
    }

    &_align_end {
      justify-content: flex-end;
    }

    &_disable-padding_right {
      padding-right: 0;
    }

    &_disable-padding_left {
      padding-left: 0;
    }

    &_disable-padding_top {
      padding-top: 0;
    }

    &_disable-padding_bottom {
      padding-bottom: 0;
    }

    &_disable-padding_horizontal {
      padding-left: 0;
      padding-right: 0;
    }

    &_disable-padding_vertical {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
