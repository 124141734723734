.contest-modal {
  padding: 15px;
  display: block;

  &__input,
  &__cards {
    margin-top: 15px;
  }

  &__stake-insurance-button {
    width: 100%;
    margin-top: 20px;
  }
}
