.trade-widget-cards {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;

  .value-card {
    width: fit-content;
    min-width: 150px;
    flex: 1;
    margin: 0 5px;
  }
}
