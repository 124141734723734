@import 'styles/variables/common';
@import 'styles/variables/colors';

.layout {
  &__container {
    display: flex;
    flex-flow: column;
    height: 100vh;
  }

  &__header {
    border-bottom: 1px solid $color-border;
  }

  &__main {
    flex-grow: 1;
    display: flex;
    height: calc(100vh - #{$header-height});

    &-content {
      flex-grow: 1;
      padding: 50px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .layout {
    &__main {
      &-content {
        padding: 5px;
      }
    }
  }
}
