.leverage-yield-farm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__follow-link {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
  }
}
