@import 'styles/variables/colors';

.liquidity {
  width: 400px;

  &__container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__divider {
    margin: 10px 0;
    height: 1px;
    width: calc(100% + 30px);
    transform: translateX(-15px);
    background: $color-bg-2;
  }
}
