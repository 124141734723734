@import 'styles/variables/colors';

.your-liquidity-ep-content {
  &__row {
    display: flex;
    justify-content: space-between;

    &:hover {
      background: $color-bg-2;
    }
  }

  &__remove-button {
    margin-top: 10px;
  }

  &__label {
    font-weight: bold;
  }

  &__label, &__value {
    font-size: 12px;
  }
}
