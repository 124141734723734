@import 'styles/variables/colors';

.expansion-panel {
  $r: &;
  position: relative;
  border: 1px solid $color-bg-2;
  border-radius: 4px;

  &__header-container {
    display: flex;
    justify-content: space-between;
    transition: background 500ms;
    cursor: pointer;
    padding: 5px;
    align-items: center;

    #{$r}:not(#{$r}_expanded) &:hover {
      background: $color-bg-2;
    }
  }

  &__icon {
    transform: rotate(180deg);

    & path {
      fill: darken($color-fg, 20%);
    }

    #{$r}_expanded  & {
      transform: rotate(0deg);
    }
  }

  &__content {
    transition: height 200ms;
    overflow: hidden;
    padding: 5px;

    #{$r}:not(#{$r}_expanded) &_initial {
      position: absolute;
      visibility: hidden;
    }

    #{$r}:not(#{$r}_expanded) &:not(&_initial) {
      visibility: hidden;
      height: 0px;
      padding: 0px;
    }

    #{$r}_expanded  & {
      visibility: visible;
    }
  }
}
