@import 'styles/variables/colors';

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.tooltip {
  &__text {
    position: absolute;
    background: $color-bg-2;
    padding: 5px;
    border-radius: 5px;
    animation: fadeIn 500ms;
    font-size: 10px;

    &_size {
      &_m {
        max-width: 150px;
      }
    }
  }
}
