.market-boards-list {
  display: grid;
  justify-content: center;
  grid-template-columns: 400px;
}

@media only screen and (max-width: 980px) {
  .market-boards-list {
    grid-template-columns: 100%;
  }
}
