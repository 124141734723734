@import 'styles/variables/colors';

$social-colors: (
  'twitter': #3cf,
  'telegram': #0088cc,
  'discord': #7289da,
  'medium': #000000,
  'docs': #f77d05,
);

.social-links {
  position: absolute;
  bottom: 0;
  right: 30px;

  width: 250px;
  height: 85px;
  background-color: $color-bg-0;

  padding: 10px 15px;

  &__links-container {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__link {
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    border-radius: 50%;

    @each $name, $color in $social-colors {
      &_color_#{$name} {
        background-color: $color;
      }
    }
  }
}
