@import 'styles/variables/colors';

.switch {
  $r: &;
  cursor: pointer;
  display: block;

  &__input:checked + #{$r}__thumb {
    transform: translateX(44px);
  }

  &__track {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    width: 100px;
    height: 36px;
    border-radius: 100px;
    background-color: $color-bg-3;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    user-select: none;

    &_position_left {
      padding-left: 4px;
    }

    &_position_right {
      padding-right: 4px;
    }
  }

  &__thumb {
    box-sizing: content-box;
    position: absolute;
    background-color: $color-primary;
    border-radius: 100px;
    width: 55px;
    height: 100%;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
