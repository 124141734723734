.stakes-ratio {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 7px;
  align-items: center;

  &__bars-container {
    display: grid;
    grid-column-gap: 2px;
  }

  &__label {
    font-size: 12px;
    text-transform: uppercase;

    &_type {
      &_long {
        color: #3cc682;
      }

      &_short {
        color: #e36362;
      }
    }
  }

  &__bar {
    height: 7px;
    border-radius: 2px;

    &_type_long {
      background-color: #3cc682;
    }

    &_type_short {
      background-color: #e36362;
    }
  }
}
