.your-liquidity {
  &__liquidity {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__preloader {
    display: flex;
    justify-content: center;
  }

  &__profit {
    margin-bottom: 15px;
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
