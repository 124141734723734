.limiter-right-part {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    display: flex;
    justify-content: flex-end;
  }

  &__bottom {
    margin-top: 5px;
    display: flex;
    align-items: flex-end;
  }

  &__max-button {
    margin-right: 5px;
  }
}
