.unstake-insurance-modal {
  padding: 15px;
  display: block;

  &__input {
    margin-top: 15px;
  }

  &__unstake-insurance-button {
    width: 100%;
    margin-top: 20px;
  }
}
