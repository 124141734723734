.contest-steps {
  &__steps {
    display: grid;
    grid-row-gap: 10px;
  }

  &__step-content {
    display: flex;
    align-items: center;
  }

  &__step-button {
    margin-right: 15px;
  }

  &__link {
    text-decoration: none;

    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }

  &__twitter-following {
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
  }

  &__commitment-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 40px;
  }

  &__commitment-form &__input {
    margin: 0 5px;
    flex: 1;
  }

  &__submit-button {
    margin: 0 5px;
    flex: 1;
    max-width: 100px;
  }

  &__claim-form {
    margin: 0 45px;
  }

  &__submit-claim-button {
    margin: 0;
    flex: 1;
    width: 100%;
  }
}
