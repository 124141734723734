.add-liquidity-modal {
  padding: 15px;
  display: block;

  &__input, &__cards {
    margin-top: 15px;
  }

  &__add-liquidity-button {
    width: 100%;
    margin-top: 20px;
  }
}
