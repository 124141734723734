.insurance-widget {
  width: 400px;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__value-cards, &__earned, &__staked {
    margin-top: 10px;
  }
}
