$color-bg: #14142b;
$color-bg-0: lighten($color-bg, 3%);
$color-bg-1: #262338;
$color-bg-2: lighten($color-bg, 4%);
$color-bg-2: lighten($color-bg, 8%);
$color-bg-3: lighten($color-bg, 12%);
$color-fg: #ffffff;
$color-fg-inverted: lighten(black, 20%);
$color-light-bg: rgb(240, 240, 240);
$color-light-fg: darken($color-fg, 30%);
$color-primary: rgb(31, 161, 204);
$color-danger: #e36362;
$color-positive: #3cc682;
$color-accent: #ffc107;
$color-border: #383536;
$color-green: rgb(11, 179, 11);
$color-red: #ff0000;
$color-error: #f91212;
$color-link: $color-primary;
