@import 'styles/variables/colors';

.market-name {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;

  &__icon {
    display: block;
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
  }

  &__arrow {
    margin: 0 5px;
  }
}
