@import 'styles/variables/colors';

.range-input {
  position: relative;
  display: block;

  &__slider {
    &_error {
      color: red !important;
    }
  }

  &__mark-label {
    color: darken($color-fg, 40%) !important;

    &_active {
      color: darken($color-fg, 10%) !important;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: -12px;
    color: $color-error;
  }
}
